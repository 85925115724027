// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".turbo-mode_turbo-container_E5cjR {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    padding: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.turbo-mode_turbo-icon_e6vb4 {\n    margin: 0.25rem;\n}\n\n.turbo-mode_turbo-label_ZHyo1 {\n    font-size: 0.625rem;\n    font-weight: bold;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: var(--clipcc-control-primary);\n    white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/turbo-mode/turbo-mode.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,qBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,yBAAmB;IAAnB,2BAAmB;QAAnB,sBAAmB;YAAnB,mBAAmB;IACnB,gBAAgB;IAChB,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,2DAA2D;IAC3D,oCAAoC;IACpC,mBAAmB;AACvB","sourcesContent":[".turbo-container {\n    display: flex;\n    align-items: center;\n    padding: 0.25rem;\n    user-select: none;\n}\n\n.turbo-icon {\n    margin: 0.25rem;\n}\n\n.turbo-label {\n    font-size: 0.625rem;\n    font-weight: bold;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: var(--clipcc-control-primary);\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"turbo-container": "turbo-mode_turbo-container_E5cjR",
	"turboContainer": "turbo-mode_turbo-container_E5cjR",
	"turbo-icon": "turbo-mode_turbo-icon_e6vb4",
	"turboIcon": "turbo-mode_turbo-icon_e6vb4",
	"turbo-label": "turbo-mode_turbo-label_ZHyo1",
	"turboLabel": "turbo-mode_turbo-label_ZHyo1"
};
export default ___CSS_LOADER_EXPORT___;
