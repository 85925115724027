// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".player_stage-only_XGueU {\n    width: calc(480px + 1rem);\n}\n\n.player_editor_IosHP {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n}\n\n.player_stage-only_XGueU * {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/playground/player.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,8BAAsB;YAAtB,sBAAsB;AAC1B","sourcesContent":[".stage-only {\n    width: calc(480px + 1rem);\n}\n\n.editor {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n}\n\n.stage-only * {\n    box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stage-only": "player_stage-only_XGueU",
	"stageOnly": "player_stage-only_XGueU",
	"editor": "player_editor_IosHP"
};
export default ___CSS_LOADER_EXPORT___;
