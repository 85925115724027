// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".green-flag_green-flag_YzYwH {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    user-drag: none;\n    cursor: pointer;\n}\n\n.green-flag_green-flag_YzYwH:hover {\n    background-color: var(--clipcc-motion-light-transparent);\n}\n\n.green-flag_green-flag_YzYwH.green-flag_is-active_Kh7At {\n    background-color: var(--clipcc-motion-transparent);\n}\n", "",{"version":3,"sources":["webpack://./src/components/green-flag/green-flag.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;IACtB,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,wDAAwD;AAC5D;;AAEA;IACI,kDAAkD;AACtD","sourcesContent":[".green-flag {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    user-select: none;\n    user-drag: none;\n    cursor: pointer;\n}\n\n.green-flag:hover {\n    background-color: var(--clipcc-motion-light-transparent);\n}\n\n.green-flag.is-active {\n    background-color: var(--clipcc-motion-transparent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green-flag": "green-flag_green-flag_YzYwH",
	"greenFlag": "green-flag_green-flag_YzYwH",
	"is-active": "green-flag_is-active_Kh7At",
	"isActive": "green-flag_is-active_Kh7At"
};
export default ___CSS_LOADER_EXPORT___;
