// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loupe_color-picker_eydi8 {\n    position: absolute;\n    border-radius: 100%;\n    border: 4px solid var(--clipcc-ui-black-transparent);\n}\n", "",{"version":3,"sources":["webpack://./src/components/loupe/loupe.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,oDAAoD;AACxD","sourcesContent":[".color-picker {\n    position: absolute;\n    border-radius: 100%;\n    border: 4px solid var(--clipcc-ui-black-transparent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-picker": "loupe_color-picker_eydi8",
	"colorPicker": "loupe_color-picker_eydi8"
};
export default ___CSS_LOADER_EXPORT___;
