// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stop-all_stop-all_kLygy {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n}\n\n.stop-all_stop-all_kLygy:hover {\n    background-color: var(--clipcc-motion-light-transparent);\n}\n\n.stop-all_stop-all_kLygy {\n    opacity: 0.5;\n}\n\n.stop-all_stop-all_kLygy.stop-all_is-active_vUUE6 {\n    opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/stop-all/stop-all.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;IACtB,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,wDAAwD;AAC5D;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".stop-all {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    user-select: none;\n    cursor: pointer;\n}\n\n.stop-all:hover {\n    background-color: var(--clipcc-motion-light-transparent);\n}\n\n.stop-all {\n    opacity: 0.5;\n}\n\n.stop-all.is-active {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stop-all": "stop-all_stop-all_kLygy",
	"stopAll": "stop-all_stop-all_kLygy",
	"is-active": "stop-all_is-active_vUUE6",
	"isActive": "stop-all_is-active_vUUE6"
};
export default ___CSS_LOADER_EXPORT___;
