// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inline-message_inline-message_c8YGH {\n    color: var(--clipcc-ui-white);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: end;\n    -webkit-justify-content: flex-end;\n        -ms-flex-pack: end;\n            justify-content: flex-end;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    font-size: .8125rem;\n}\n\n.inline-message_success_ovPLP {\n    color: var(--clipcc-ui-white-dim);\n}\n\n.inline-message_info_RsKL0 {\n    color: var(--clipcc-ui-white);\n}\n\n.inline-message_warn_iVBOJ {\n    color: var(--clipcc-error-light);\n}\n\n.inline-message_spinner_soeyU {\n    margin-right: var(--clipcc-space);\n}\n", "",{"version":3,"sources":["webpack://./src/components/alerts/inline-message.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,2DAA2D;IAC3D,oBAAa;IAAb,qBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,qBAAyB;IAAzB,iCAAyB;QAAzB,kBAAyB;YAAzB,yBAAyB;IACzB,yBAAmB;IAAnB,2BAAmB;QAAnB,sBAAmB;YAAnB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".inline-message {\n    color: var(--clipcc-ui-white);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    font-size: .8125rem;\n}\n\n.success {\n    color: var(--clipcc-ui-white-dim);\n}\n\n.info {\n    color: var(--clipcc-ui-white);\n}\n\n.warn {\n    color: var(--clipcc-error-light);\n}\n\n.spinner {\n    margin-right: var(--clipcc-space);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline-message": "inline-message_inline-message_c8YGH",
	"inlineMessage": "inline-message_inline-message_c8YGH",
	"success": "inline-message_success_ovPLP",
	"info": "inline-message_info_RsKL0",
	"warn": "inline-message_warn_iVBOJ",
	"spinner": "inline-message_spinner_soeyU"
};
export default ___CSS_LOADER_EXPORT___;
