// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-avatar_user-thumbnail_PQgAt {\n    width: var(--clipcc-menu-bar-button-size);\n    height: var(--clipcc-menu-bar-button-size);\n    border-radius: var(--clipcc-form-radius);\n    vertical-align: middle;\n    -webkit-box-shadow: 0 0 0 1px var(--clipcc-ui-black-transparent);\n            box-shadow: 0 0 0 1px var(--clipcc-ui-black-transparent);\n}\n", "",{"version":3,"sources":["webpack://./src/components/menu-bar/user-avatar.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,0CAA0C;IAC1C,wCAAwC;IACxC,sBAAsB;IACtB,gEAAwD;YAAxD,wDAAwD;AAC5D","sourcesContent":[".user-thumbnail {\n    width: var(--clipcc-menu-bar-button-size);\n    height: var(--clipcc-menu-bar-button-size);\n    border-radius: var(--clipcc-form-radius);\n    vertical-align: middle;\n    box-shadow: 0 0 0 1px var(--clipcc-ui-black-transparent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-thumbnail": "user-avatar_user-thumbnail_PQgAt",
	"userThumbnail": "user-avatar_user-thumbnail_PQgAt"
};
export default ___CSS_LOADER_EXPORT___;
