// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes mic-indicator_popIn_F3vg4 {\n    from {-webkit-transform: scale(0.5);transform: scale(0.5)}\n    to {-webkit-transform: scale(1);transform: scale(1)}\n}\n\n@keyframes mic-indicator_popIn_F3vg4 {\n    from {-webkit-transform: scale(0.5);transform: scale(0.5)}\n    to {-webkit-transform: scale(1);transform: scale(1)}\n}\n\n.mic-indicator_mic-img_s3s9d {\n    margin: 10px;\n    -webkit-transform-origin: center;\n        -ms-transform-origin: center;\n            transform-origin: center;\n    -webkit-animation: mic-indicator_popIn_F3vg4 0.1s ease-in-out;\n            animation: mic-indicator_popIn_F3vg4 0.1s ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://./src/components/mic-indicator/mic-indicator.css"],"names":[],"mappings":"AAAA;IACI,MAAM,6BAAoB,CAApB,qBAAqB;IAC3B,IAAI,2BAAkB,CAAlB,mBAAmB;AAC3B;;AAHA;IACI,MAAM,6BAAoB,CAApB,qBAAqB;IAC3B,IAAI,2BAAkB,CAAlB,mBAAmB;AAC3B;;AAEA;IACI,YAAY;IACZ,gCAAwB;QAAxB,4BAAwB;YAAxB,wBAAwB;IACxB,6DAAiC;YAAjC,qDAAiC;AACrC","sourcesContent":["@keyframes popIn {\n    from {transform: scale(0.5)}\n    to {transform: scale(1)}\n}\n\n.mic-img {\n    margin: 10px;\n    transform-origin: center;\n    animation: popIn 0.1s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mic-img": "mic-indicator_mic-img_s3s9d",
	"micImg": "mic-indicator_mic-img_s3s9d",
	"popIn": "mic-indicator_popIn_F3vg4"
};
export default ___CSS_LOADER_EXPORT___;
