// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".direction-picker_button-row_yOYJh {\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/direction-picker/direction-picker.css"],"names":[],"mappings":"AAAA;IACI,wBAAuB;IAAvB,+BAAuB;QAAvB,qBAAuB;YAAvB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".button-row {\n    justify-content: center;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-row": "direction-picker_button-row_yOYJh",
	"buttonRow": "direction-picker_button-row_yOYJh"
};
export default ___CSS_LOADER_EXPORT___;
