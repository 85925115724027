// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".share-button_share-button_c_HWq {\n    background: var(--clipcc-data-primary);\n}\n\n.share-button_share-button-is-shared_H9twp {\n    background: var(--clipcc-ui-black-transparent);\n    cursor: default;\n}\n", "",{"version":3,"sources":["webpack://./src/components/menu-bar/share-button.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C;;AAEA;IACI,8CAA8C;IAC9C,eAAe;AACnB","sourcesContent":[".share-button {\n    background: var(--clipcc-data-primary);\n}\n\n.share-button-is-shared {\n    background: var(--clipcc-ui-black-transparent);\n    cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"share-button": "share-button_share-button_c_HWq",
	"shareButton": "share-button_share-button_c_HWq",
	"share-button-is-shared": "share-button_share-button-is-shared_H9twp",
	"shareButtonIsShared": "share-button_share-button-is-shared_H9twp"
};
export default ___CSS_LOADER_EXPORT___;
