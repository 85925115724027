// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".community-button_community-button_BVwuM {\n    -webkit-box-shadow: 0 0 0 1px var(--clipcc-ui-black-transparent);\n            box-shadow: 0 0 0 1px var(--clipcc-ui-black-transparent);\n}\n\n.community-button_community-button-icon_XQA2b {\n    height: 1.25rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/menu-bar/community-button.css"],"names":[],"mappings":"AAAA;IACI,gEAAwD;YAAxD,wDAAwD;AAC5D;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".community-button {\n    box-shadow: 0 0 0 1px var(--clipcc-ui-black-transparent);\n}\n\n.community-button-icon {\n    height: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"community-button": "community-button_community-button_BVwuM",
	"communityButton": "community-button_community-button_BVwuM",
	"community-button-icon": "community-button_community-button-icon_XQA2b",
	"communityButtonIcon": "community-button_community-button-icon_XQA2b"
};
export default ___CSS_LOADER_EXPORT___;
