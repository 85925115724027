// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".watermark_sprite-image_qLM2F {\n    margin: auto;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    max-width: 48px;\n    max-height: 48px;\n    opacity: 0.35;\n}\n", "",{"version":3,"sources":["webpack://./src/components/watermark/watermark.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".sprite-image {\n    margin: auto;\n    user-select: none;\n    max-width: 48px;\n    max-height: 48px;\n    opacity: 0.35;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sprite-image": "watermark_sprite-image_qLM2F",
	"spriteImage": "watermark_sprite-image_qLM2F"
};
export default ___CSS_LOADER_EXPORT___;
