// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls_controls-container__T337 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/controls/controls.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,qBAAa;IAAb,oBAAa;IAAb,aAAa;AACjB","sourcesContent":[".controls-container {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls-container": "controls_controls-container__T337",
	"controlsContainer": "controls_controls-container__T337"
};
export default ___CSS_LOADER_EXPORT___;
