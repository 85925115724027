// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag-button_tag-button_Lm1LH {\n    padding: .625rem 1rem;\n    background: var(--clipcc-motion-primary);\n    border-radius: 1.375rem;\n    color: var(--clipcc-on-motion-primary);\n    height: var(--clipcc-library-filter-bar-height);\n}\n\n.tag-button_tag-button-icon_iFHFX {\n    max-width: 1rem;\n    max-height: 1rem;\n}\n\n.tag-button_active_uwCfT {\n    background: var(--clipcc-data-primary);\n}\n", "",{"version":3,"sources":["webpack://./src/components/tag-button/tag-button.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,wCAAwC;IACxC,uBAAuB;IACvB,sCAAsC;IACtC,+CAA+C;AACnD;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".tag-button {\n    padding: .625rem 1rem;\n    background: var(--clipcc-motion-primary);\n    border-radius: 1.375rem;\n    color: var(--clipcc-on-motion-primary);\n    height: var(--clipcc-library-filter-bar-height);\n}\n\n.tag-button-icon {\n    max-width: 1rem;\n    max-height: 1rem;\n}\n\n.active {\n    background: var(--clipcc-data-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag-button": "tag-button_tag-button_Lm1LH",
	"tagButton": "tag-button_tag-button_Lm1LH",
	"tag-button-icon": "tag-button_tag-button-icon_iFHFX",
	"tagButtonIcon": "tag-button_tag-button-icon_iFHFX",
	"active": "tag-button_active_uwCfT"
};
export default ___CSS_LOADER_EXPORT___;
