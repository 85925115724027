// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".divider_divider_Ny838 {\n    border-right: 1px dashed var(--clipcc-ui-black-transparent);\n}\n", "",{"version":3,"sources":["webpack://./src/components/divider/divider.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;AAC/D","sourcesContent":[".divider {\n    border-right: 1px dashed var(--clipcc-ui-black-transparent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "divider_divider_Ny838"
};
export default ___CSS_LOADER_EXPORT___;
