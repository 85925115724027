// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_outlined-button_R8Fss {\n    cursor: pointer;\n    border-radius: var(--clipcc-form-radius);\n    font-weight: bold;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    padding-left: .75rem;\n    padding-right: .75rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.button_icon_Zg8pD {\n    height: 1.5rem;\n}\n\n[dir=\"ltr\"] .button_icon_Zg8pD {\n    margin-right: .5rem;\n}\n\n[dir=\"rtl\"] .button_icon_Zg8pD {\n    margin-left: .5rem;\n}\n\n.button_content_NmxUc {\n    white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/button/button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,wCAAwC;IACxC,iBAAiB;IACjB,oBAAa;IAAb,qBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,8BAAmB;IAAnB,6BAAmB;IAAnB,2BAAmB;QAAnB,uBAAmB;YAAnB,mBAAmB;IACnB,yBAAmB;IAAnB,2BAAmB;QAAnB,sBAAmB;YAAnB,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;IACrB,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".outlined-button {\n    cursor: pointer;\n    border-radius: var(--clipcc-form-radius);\n    font-weight: bold;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-left: .75rem;\n    padding-right: .75rem;\n    user-select: none;\n}\n\n.icon {\n    height: 1.5rem;\n}\n\n[dir=\"ltr\"] .icon {\n    margin-right: .5rem;\n}\n\n[dir=\"rtl\"] .icon {\n    margin-left: .5rem;\n}\n\n.content {\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outlined-button": "button_outlined-button_R8Fss",
	"outlinedButton": "button_outlined-button_R8Fss",
	"icon": "button_icon_Zg8pD",
	"content": "button_content_NmxUc"
};
export default ___CSS_LOADER_EXPORT___;
