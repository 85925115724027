// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".waveform_container_VS2m6 {\n    width: 100%;\n}\n.waveform_waveform-path_u8Gdu {\n    /*\n        This color is lighter than sound-primary, but\n        cannot use alpha because of overlapping elements.\n    */\n    fill: hsl(300, 54%, 72%);\n    stroke: var(--clipcc-sound-tertiary);\n}\n.waveform_baseline_lltQ4 {\n    stroke: var(--clipcc-sound-tertiary);\n}\n", "",{"version":3,"sources":["webpack://./src/components/waveform/waveform.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI;;;KAGC;IACD,wBAAwB;IACxB,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC","sourcesContent":[".container {\n    width: 100%;\n}\n.waveform-path {\n    /*\n        This color is lighter than sound-primary, but\n        cannot use alpha because of overlapping elements.\n    */\n    fill: hsl(300, 54%, 72%);\n    stroke: var(--clipcc-sound-tertiary);\n}\n.baseline {\n    stroke: var(--clipcc-sound-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "waveform_container_VS2m6",
	"waveform-path": "waveform_waveform-path_u8Gdu",
	"waveformPath": "waveform_waveform-path_u8Gdu",
	"baseline": "waveform_baseline_lltQ4"
};
export default ___CSS_LOADER_EXPORT___;
