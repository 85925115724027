// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progress-ring_progress-ring_vqQwf {\n    margin: 0.5rem;\n}\n\n.progress-ring_progress-ring-svg_Lhzob {\n    fill: transparent;\n}\n\n.progress-ring_progress-ring_vqQwf .progress-ring_progress-ring-ring_EgOSZ {\n    stroke: var(--clipcc-motion-light-transparent);\n}\n\n.progress-ring_progress-ring_vqQwf .progress-ring_progress-ring-value_kbwg7 {\n    stroke: var(--clipcc-motion-primary);\n    stroke-linecap: round;\n}\n", "",{"version":3,"sources":["webpack://./src/components/progress-ring/progress-ring.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,oCAAoC;IACpC,qBAAqB;AACzB","sourcesContent":[".progress-ring {\n    margin: 0.5rem;\n}\n\n.progress-ring-svg {\n    fill: transparent;\n}\n\n.progress-ring .progress-ring-ring {\n    stroke: var(--clipcc-motion-light-transparent);\n}\n\n.progress-ring .progress-ring-value {\n    stroke: var(--clipcc-motion-primary);\n    stroke-linecap: round;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress-ring": "progress-ring_progress-ring_vqQwf",
	"progressRing": "progress-ring_progress-ring_vqQwf",
	"progress-ring-svg": "progress-ring_progress-ring-svg_Lhzob",
	"progressRingSvg": "progress-ring_progress-ring-svg_Lhzob",
	"progress-ring-ring": "progress-ring_progress-ring-ring_EgOSZ",
	"progressRingRing": "progress-ring_progress-ring-ring_EgOSZ",
	"progress-ring-value": "progress-ring_progress-ring-value_kbwg7",
	"progressRingValue": "progress-ring_progress-ring-value_kbwg7"
};
export default ___CSS_LOADER_EXPORT___;
