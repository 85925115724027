// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meter_green_KIMF6 {\n    fill: rgb(171, 220, 170);\n    stroke: rgb(174, 211, 168);\n}\n\n.meter_yellow_apqdC {\n    fill: rgb(251, 219, 130);\n    stroke: rgb(239, 212, 134);\n}\n\n.meter_red_l1IMM {\n    fill: rgb(251, 194, 142);\n    stroke: rgb(235, 189, 142);\n}\n\n.meter_mask-container_TBjcW {\n    position: relative;\n}\n\n.meter_mask_Tt0aS {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    -webkit-transform-origin: top;\n        -ms-transform-origin: top;\n            transform-origin: top;\n    will-change: transform;\n    background: var(--clipcc-ui-primary);\n    opacity: 0.75;\n}\n", "",{"version":3,"sources":["webpack://./src/components/meter/meter.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;IACX,6BAAqB;QAArB,yBAAqB;YAArB,qBAAqB;IACrB,sBAAsB;IACtB,oCAAoC;IACpC,aAAa;AACjB","sourcesContent":[".green {\n    fill: rgb(171, 220, 170);\n    stroke: rgb(174, 211, 168);\n}\n\n.yellow {\n    fill: rgb(251, 219, 130);\n    stroke: rgb(239, 212, 134);\n}\n\n.red {\n    fill: rgb(251, 194, 142);\n    stroke: rgb(235, 189, 142);\n}\n\n.mask-container {\n    position: relative;\n}\n\n.mask {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    transform-origin: top;\n    will-change: transform;\n    background: var(--clipcc-ui-primary);\n    opacity: 0.75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green": "meter_green_KIMF6",
	"yellow": "meter_yellow_apqdC",
	"red": "meter_red_l1IMM",
	"mask-container": "meter_mask-container_TBjcW",
	"maskContainer": "meter_mask-container_TBjcW",
	"mask": "meter_mask_Tt0aS"
};
export default ___CSS_LOADER_EXPORT___;
